import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import { Kochava } from 'kochava';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    kochava: null,
  },
  getters: {
  },
  actions: {
  },

  mutations: {
    storekochava(state) {
      //console.log("[KOCHAVA]: initiating")
      state.kochava = new Kochava(
        "kotrackd-web-ohshv09",
        true,
        true,
        false,
        {},
      )
    },
    kochavaIdentify(state) {
      //console.log("[KOCHAVA]: Identifying user")
      if (!state.kochava) {
        this.storekochava(state)
      }
      // kochava identify user elevated
      state.kochava.identify({
        user_id: String(state.userid),
        signup_source: state.signup_source,
        username: state.username,
        supporter: state.supporter,
        user_type: state.user_type,
        login_type: state.email,
        account_type: state.account_type
      }, (e,v)=>console.log(v))
    },
    kochavaEvent(state, name, data) {
      //console.log(`[KOCHAVA]: Custom Event ${name}`)
      if (!state.kochava) {
        this.storekochava(state)
      }
      // custom event
      state.kochava.activity(
        name, 
        data, 
        (e,t)=>console.log(t)
      )
    },
    kochavaPage(state) {
      //console.log("[KOCHAVA]: Page Event")
      if (!state.kochava) {
        this.storekochava(state)
      }
      // page event
      state.kochava.page()
    }
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
