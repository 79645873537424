<template>
  <div class="w-100">
    <b-navbar
      toggleable="lg"
      fixed="top"
      type="dark"
      variant="dark"
      class="fixed-top py-0"
      v-bind:class="{
        'c-topbar--collapsed': topBarIsCollapsed,
      }"
    >
      <b-container
        fluid
        class="pt-2 nav-small"
        :class="{
          'topbar-content-collapsed': topBarIsCollapsed,
        }"
        :style="{
          height: header_height,
        }"
      >
        <b-col cols="4">

        </b-col>

        <b-col cols="4"  class="pl-0 pr-0 text-center">
          <b-button
            v-if="!topBarIsCollapsed || !use_collapsable_header"
            variant="link"
            href=""
          >
            <img
              src="../assets/img/trackd-social-music-platform.svg"
              class="mx-auto logo trackd-logo text-center"
              alt="Logo"
            />
          </b-button>
          <b-row v-else>
            <b-col cols="12">
              <a href="">
                <img
                  src="../assets/img/trackd-icon-white.svg"
                  class="mx-auto white-logo text-center d-none d-md-inline-block"
                  alt="Logo"
                />
              </a>
            </b-col>
            <b-col>
              <img
                @click="toggleExpand()"
                src="../assets/img/arrow-down.svg"
                class="mx-auto text-center d-none d-md-inline-block"
                alt="Logo"
                height="10"
              />
            </b-col>
          </b-row>
        </b-col>



        <b-col cols="4" class="align-self-center">

        </b-col>

      </b-container>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      topBarIsCollapsed: false,
    };
  },
  mounted() {
    
    let that = this;

    window.addEventListener("scroll", function() {
      let scroll = this.scrollY;

      if (scroll > 0) {
        that.topBarIsCollapsed = true;
        return;
      }

      that.topBarIsCollapsed = false;
    });
  },
  computed: {
    use_collapsable_header() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        return true;
      } else {
        return false;
      }
    },

    header_height() {
      const large_header = window.matchMedia("(min-width: 576px)");
      
      if (this.$route.name === 'Landing') {
        return (large_header) ? '100px' : '80px';
      }
      else {
        return (large_header) ? '120px' : '80px';
      }
  
    }
  },
  methods: {
    toggleExpand() {
      this.topBarIsCollapsed = !this.topBarIsCollapsed;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-topbar--collapsed .nav-container,
.c-topbar--collapsed {
  height: 75px;
}


.c-vertical-hamburger,
.c-back-icon {
  opacity: 0.8;
}


.bg-dark-important {
  background: rgba(1, 1, 1, 0.75) !important;
}

.nav-small {
  height: 80px;
  transition: all 0.5s ease-in-out;
}

.fade-in {
  transition: all 1s ease-in-out;
}

.fade-in-long {
  transition: all 1s ease-in-out;
}

.show-icon {
  display: block !important;
  transition: all 1s ease-in-out;
}

.create-account-button {
  width: 144px;
  height: 30px;
  font-size: 14px;
  border-radius: 10px;
}

.menu-icon {
  height: 22px;
  transform: translate(-27%, -32%);
  float: left;
}

.app-store-icon {
  display: none;
}

.start-button {
  transform: translate(30%, -40%);
  width: 120px;
}

.filter-icon {
  transform: translateX(60%);
  display: none;
}

.filter-icon-mobile {
  transform: translate(42%, -20%);
  height: 24px;
}

.nav-bar-button-icon {
  height: 40px;
  width: 40px;
}

@media (max-width: 575.98px) {
  .trackd-logo {
    height: 40px;
    transform: translate(-12.5%, -8%);
  }

  .for-artists {
    display: none;
  }

  .for-artists-icon {
    display: inline-block;
    transform: translate(-22%, -29%);
  }

  .app-store-icon {
    display: none;
  }

  .hamburger {
    transform: translate(20%, -25%);
    height: 20px;
    width: 25px;
  }
}

@media (min-width: 576px) {
  .for-artists {
    display: none;
  }

  .for-artists-icon {
    display: inline-block;
    transform: translate(7%, -29%);
  }

  .trackd-logo {
    height: 40px;
    transform: translate(2.2%, -7.5%);
  }

  .filter-icon {
    transform: translate(112%, -47%);
    height: 16.2px;
    display: inline-block;
  }

  .filter-icon-mobile {
    display: none;
  }

  .hamburger {
    transform: translate(20%, -25%);
    height: 20px;
    width: 30px;
  }

  .menu-icon {
    height: 22px;
    transform: translate(-27%, -32%);
  }

  .nav-small {
    height: 120px;
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width: 768px) {

  .menu-icon {
    height: 20px;
    transform: translate(9%, 5%);
  }

  .for-artists {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 200;
    transform: translate(50%, 15%);
  }

  .for-artists-icon {
    display: none;
  }

  .create-account-button {
    height: 30px;
    font-size: 14px;
    text-align: center;
    padding: 0;
    border-radius: 10px;
  }

  .filter-icon {
    transform: translate(178%, 10%);
    height: 21px;
    display: inline-block;
  }

  .filter-icon-mobile {
    display: none;
  }

  .hamburger {
    transform: translate(25%, 15%);
    height: 20px;
    width: 20px;
  }

  .start-button {
    width: 120px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 8px;
    line-height: 22px;
    transform: translate(43%, -10%);
  }

  .trackd-logo {
    height: 40px;
    transform: translate(-9%, 10%);
  }
}

@media (min-width: 992px) {

  .filter-icon {
    height: 22px;
    transform: translate(35%, 5%);
  }

  .hamburger {
    transform: translate(25%, 9%);
    height: 20px;
    width: 20px;
  }

  .start-button {
    width: 120px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 22px;
    transform: translate(-10%, -20%);
  }

  .for-artists {
    float: right;
    font-size: 15px;
    font-weight: 200;
    transform: translate(0%, 0%);
  }

  .trackd-logo {
    transform: translate(2.5%, 2%);
  }

  .menu-icon {
    height: 20px;
    transform: translate(-15%, -3%);
  }
}

@media (min-width: 1200px) {

  .trackd-logo {
    height: 54px;
    transform: translate(1.8%, -2%);
  }

  .menu-icon {
    height: 30px;
    transform: translate(-11%, -22%);
  }

  .app-store-icon {
    display: inline-block;
    height: 35px;
    transform: translate(-1%, -14%);
  }

  .for-artists {
    float: right;
    font-size: 15px;
    font-weight: 200;
    transform: translate(39%, -32%);
  }

  .start-button {
    width: 144px;
    height: 35px;
    font-size: 15px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 22px;
    transform: translate(5.5%, -25%);
  }

  .filter-icon {
    height: 22px;
    transform: translate(120%, -15%);
  }

  .hamburger {
    transform: translate(0%, -8%);
    height: 27px;
    width: 27px;
  }

  .white-logo {
    height: 27px;
  }
}

@media (max-width: 576px) {
  .nav-bar-button-icon {
    width: 30px;
    height: 30px;
  }

  .topbar-content-collapsed {
    transition: all 0.3s ease-in-out;
    transform: translateY(-22px);
  }
}

@media (max-width: 320px) {
  .nav-bar-button-icon {
    width: 25px;
    height: 25px;
  }
}
</style>
