import axios from "axios";

import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import VueSmoothScroll from "vue2-smooth-scroll";
import VueAB from "vue-a2b";
var VueCookie = require('vue-cookie');


Vue.config.productionTip = process.env.NODE_ENV === "production";

// BOOTSTRAP

import "./assets/scss/custom.scss";

// AXIOS

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API_URL;

const token = process.env.VUE_APP_CLIENT_API_KEY;

axios.interceptors.request.use(
  (config) => {

    config.headers.Authorization = "Api-Key " + token;
      
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Vue.use(BootstrapVue);
Vue.use(VueCookie);
Vue.use(VueMeta)
Vue.use(VueSmoothScroll);
Vue.use(VueAB);
Vue.use(VueRouter);

store.commit("storekochava");
store.commit("kochavaIdentify");

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
