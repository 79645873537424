import Vue from "vue";
import VueRouter from "vue-router";

//const Landing = () => import("@/pages/Landing");
const LandingA = () => import("@/pages/LandingA");
const LandingB = () => import("@/pages/LandingB");
const LandingC = () => import("@/pages/LandingC");

Vue.use(VueRouter);

/*
function requireAuth(to, from, next) {
  if (store.state.accessToken || localStorage.getItem('accessToken')) {

    next();

  } else {

    if (to.path !== "/") {
      store.commit('storeNextUrl', to.path)
      next("/");
      store.commit("Auth/setShowLoginModal", true);
    }

    next();
    

  }
}
*/
export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Landing",
      component: LandingC,
    },
    {
      path: "/a",
      name: "LandingA",
      component: LandingA,
    },
    {
      path: "/b",
      name: "LandingB",
      component: LandingB,
    },
    {
      path: "/c",
      name: "LandingC",
      component: LandingC,
    },
  ],
});
