<template>
  <div v-if="loaded" id="app" class="bg-dark text-light">
    <Header v-if="!hide_header"></Header>
    <router-view>
    </router-view>
  </div>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "App",
  data: function () {
     return {
       loaded: false
     }
  },
  components: {
    Header,
  },
  methods: {
    redirectToSite() {
      //console.log(process.env.VUE_APP_REDIRECT_URL)
      var url_append = ''

      if (
        this.$route.query.utm_campaign &&
        this.$route.query.utm_source &&
        this.$route.query.utm_medium
      ) {

        url_append += '&utm_campaign=' + this.$route.query.utm_campaign
        url_append += '&utm_source=' + this.$route.query.utm_source
        url_append += '&utm_medium=' + this.$route.query.utm_medium

      }

      window.location = process.env.VUE_APP_REDIRECT_URL + url_append


    }
  },
  computed: {
    hide_header() {
      if (
        this.$route.name === 'Landing' ||
        this.$route.name === 'LandingA' ||
        this.$route.name === 'LandingB' || 
        this.$route.name === 'LandingC' 
      ) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  mounted() {

    this.$router.onReady(() => {

      this.loaded = true
    
    
    });
  }
};
</script>

<style>
@font-face {
  font-family: "SFUI";
  src: url(./assets/fonts/SF-UI-Text.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SFPro";
  src: url(./assets/fonts/SF-Pro-Text.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

* {
  font-family: "Roboto";
}


.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 134, 139, 0.25) !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-12 {
  border-radius: 12px;
}

.fs-40 {
  font-size: 40px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.f-r {
  float: right !important;
}

.no-underline {
  text-decoration: none;
}

</style>
